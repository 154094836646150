*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root, [data-theme] {
  background-color: var(--fallback-b1, oklch(var(--b1) / 1));
  color: var(--fallback-bc, oklch(var(--bc) / 1));
}

@supports not (color: oklch(0% 0 0)) {
  :root {
    --lightningcss-light: initial;
    --lightningcss-dark: ;
    color-scheme: light;
    --fallback-p: #491eff;
    --fallback-pc: #d4dbff;
    --fallback-s: #ff41c7;
    --fallback-sc: #fff9fc;
    --fallback-a: #00cfbd;
    --fallback-ac: #00100d;
    --fallback-n: #2b3440;
    --fallback-nc: #d7dde4;
    --fallback-b1: #fff;
    --fallback-b2: #e5e6e6;
    --fallback-b3: #e5e6e6;
    --fallback-bc: #1f2937;
    --fallback-in: #00b3f0;
    --fallback-inc: #000;
    --fallback-su: #00ca92;
    --fallback-suc: #000;
    --fallback-wa: #ffc22d;
    --fallback-wac: #000;
    --fallback-er: #ff6f70;
    --fallback-erc: #000;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --lightningcss-light: ;
      --lightningcss-dark: initial;
      color-scheme: dark;
      --fallback-p: #7582ff;
      --fallback-pc: #050617;
      --fallback-s: #ff71cf;
      --fallback-sc: #190211;
      --fallback-a: #00c7b5;
      --fallback-ac: #000e0c;
      --fallback-n: #2a323c;
      --fallback-nc: #a6adbb;
      --fallback-b1: #1d232a;
      --fallback-b2: #191e24;
      --fallback-b3: #15191e;
      --fallback-bc: #a6adbb;
      --fallback-in: #00b3f0;
      --fallback-inc: #000;
      --fallback-su: #00ca92;
      --fallback-suc: #000;
      --fallback-wa: #ffc22d;
      --fallback-wac: #000;
      --fallback-er: #ff6f70;
      --fallback-erc: #000;
    }
  }
}

html {
  -webkit-tap-highlight-color: transparent;
}

* {
  scrollbar-color: currentColor transparent;
}

:root {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
  --in: 72.06% .191 231.6;
  --su: 64.8% .15 160;
  --wa: 84.71% .199 83.87;
  --er: 71.76% .221 22.18;
  --pc: 89.824% .06192 275.75;
  --ac: 15.352% .0368 183.61;
  --inc: 0% 0 0;
  --suc: 0% 0 0;
  --wac: 0% 0 0;
  --erc: 0% 0 0;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 49.12% .3096 275.75;
  --s: 69.71% .329 342.55;
  --sc: 98.71% .0106 342.55;
  --a: 76.76% .184 183.61;
  --n: 32.1785% .02476 255.702;
  --nc: 89.4994% .011585 252.096;
  --b1: 100% 0 0;
  --b2: 96.1151% 0 0;
  --b3: 92.4169% .00108 197.138;
  --bc: 27.8078% .029596 256.848;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.avatar.placeholder > div {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (hover: hover) {
  .label a:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  }

  .menu li > :not(ul, .menu-title, details, .btn):active, .menu li > :not(ul, .menu-title, details, .btn).active, .menu li > details > summary:active {
    --tw-bg-opacity: 1;
    background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
    --tw-text-opacity: 1;
    color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
  }

  .table tr.hover:hover, .table tr.hover:nth-child(2n):hover {
    --tw-bg-opacity: 1;
    background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  }
}

.btn {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--rounded-btn, .5rem);
  border-color: #0000;
  border-color: oklch(var(--btn-color, var(--b2)) / var(--tw-border-opacity));
  text-align: center;
  border-width: var(--border-btn, 1px);
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
  background-color: oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
  --tw-border-opacity: 1;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, opacity, box-shadow, transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

.btn-disabled, .btn[disabled], .btn:disabled {
  pointer-events: none;
}

:where(.btninput[type="checkbox"]), :where(.btninput[type="radio"]) {
  appearance: none;
  width: auto;
}

.btninput[type="checkbox"]:after, .btninput[type="radio"]:after {
  --tw-content: attr(aria-label);
  content: var(--tw-content);
}

.checkbox {
  --chkbg: var(--fallback-bc, oklch(var(--bc) / 1));
  --chkfg: var(--fallback-b1, oklch(var(--b1) / 1));
  cursor: pointer;
  appearance: none;
  border-radius: var(--rounded-btn, .5rem);
  border-width: 1px;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  --tw-border-opacity: .2;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.collapse:not(td):not(tr):not(colgroup) {
  visibility: visible;
}

.collapse {
  border-radius: var(--rounded-box, 1rem);
  grid-template-rows: auto 0fr;
  width: 100%;
  transition: grid-template-rows .2s;
  display: grid;
  position: relative;
  overflow: hidden;
}

.collapse-title, .collapse > input[type="checkbox"], .collapse > input[type="radio"], .collapse-content {
  grid-row-start: 1;
  grid-column-start: 1;
}

.collapse > input[type="checkbox"], .collapse > input[type="radio"] {
  appearance: none;
  opacity: 0;
}

.collapse-content {
  visibility: hidden;
  cursor: unset;
  grid-row-start: 2;
  grid-column-start: 1;
  min-height: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: padding .2s ease-out, background-color .2s ease-out;
}

.collapse[open], .collapse-open, .collapse:focus:not(.collapse-close), .collapse:not(.collapse-close):has( > input[type="checkbox"]:checked), .collapse:not(.collapse-close):has( > input[type="radio"]:checked) {
  grid-template-rows: auto 1fr;
}

.collapse[open] > .collapse-content, .collapse-open > .collapse-content, .collapse:focus:not(.collapse-close) > .collapse-content, .collapse:not(.collapse-close) > input[type="checkbox"]:checked ~ .collapse-content, .collapse:not(.collapse-close) > input[type="radio"]:checked ~ .collapse-content {
  visibility: visible;
  min-height: fit-content;
}

.divider {
  white-space: nowrap;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  height: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.divider:before, .divider:after {
  --tw-content: "";
  content: var(--tw-content);
  background-color: var(--fallback-bc, oklch(var(--bc) / .1));
  flex-grow: 1;
  width: 100%;
  height: .125rem;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown > :not(summary):focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.dropdown .dropdown-content {
  position: absolute;
}

.dropdown:not(details) .dropdown-content {
  visibility: hidden;
  opacity: 0;
  transform-origin: top;
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.dropdown-end .dropdown-content {
  inset-inline-end: 0;
}

.dropdown-left .dropdown-content {
  bottom: auto;
  transform-origin: 100%;
  inset-inline-end: 100%;
  top: 0;
}

.dropdown-right .dropdown-content {
  bottom: auto;
  transform-origin: 0;
  inset-inline-start: 100%;
  top: 0;
}

.dropdown-bottom .dropdown-content {
  transform-origin: top;
  top: 100%;
  bottom: auto;
}

.dropdown-top .dropdown-content {
  transform-origin: bottom;
  top: auto;
  bottom: 100%;
}

.dropdown-end.dropdown-right .dropdown-content, .dropdown-end.dropdown-left .dropdown-content {
  top: auto;
  bottom: 0;
}

.dropdown.dropdown-open .dropdown-content, .dropdown:not(.dropdown-hover):focus .dropdown-content, .dropdown:focus-within .dropdown-content {
  visibility: visible;
  opacity: 1;
}

@media (hover: hover) {
  .dropdown.dropdown-hover:hover .dropdown-content {
    visibility: visible;
    opacity: 1;
  }

  .btm-nav > .disabled:hover, .btm-nav > [disabled]:hover {
    pointer-events: none;
    --tw-border-opacity: 0;
    background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
    --tw-bg-opacity: .1;
    color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
    --tw-text-opacity: .2;
  }

  .btn:hover {
    --tw-border-opacity: 1;
    border-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-border-opacity)));
    --tw-bg-opacity: 1;
    background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn:hover {
      background-color: color-mix(in oklab, oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity, 1)) 90%, black);
      border-color: color-mix(in oklab, oklch(var(--btn-color, var(--b2)) / var(--tw-border-opacity, 1)) 90%, black);
    }
  }

  @supports not (color: oklch(0% 0 0)) {
    .btn:hover {
      background-color: var(--btn-color, var(--fallback-b2));
      border-color: var(--btn-color, var(--fallback-b2));
    }
  }

  .btn.glass:hover {
    --glass-opacity: 25%;
    --glass-border-opacity: 15%;
  }

  .btn-outline:hover {
    --tw-border-opacity: 1;
    border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
    --tw-bg-opacity: 1;
    background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
    --tw-text-opacity: 1;
    color: var(--fallback-b1, oklch(var(--b1) / var(--tw-text-opacity)));
  }

  .btn-outline.btn-primary:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-primary:hover {
      background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-secondary:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-sc, oklch(var(--sc) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-secondary:hover {
      background-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-accent:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-ac, oklch(var(--ac) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-accent:hover {
      background-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-success:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-suc, oklch(var(--suc) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-success:hover {
      background-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-info:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-inc, oklch(var(--inc) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-info:hover {
      background-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-warning:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-wac, oklch(var(--wac) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-warning:hover {
      background-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-error:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-erc, oklch(var(--erc) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-error:hover {
      background-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
    }
  }

  .btn-disabled:hover, .btn[disabled]:hover, .btn:disabled:hover {
    --tw-border-opacity: 0;
    background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
    --tw-bg-opacity: .2;
    color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
    --tw-text-opacity: .2;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btninput[type="checkbox"]:checked:hover, .btninput[type="radio"]:checked:hover {
      background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    }
  }

  .dropdown.dropdown-hover:hover .dropdown-content {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.dropdown:is(details) summary::-webkit-details-marker {
  display: none;
}

.label {
  -webkit-user-select: none;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  padding: .5rem .25rem;
  display: flex;
}

.input {
  appearance: none;
  border-radius: var(--rounded-btn, .5rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  border-width: 1px;
  border-color: #0000;
  flex-shrink: 1;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.input[type="number"]::-webkit-inner-spin-button {
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-inline-end: -1rem;
}

.input-md[type="number"]::-webkit-inner-spin-button {
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-inline-end: -1rem;
}

.join {
  border-radius: var(--rounded-btn, .5rem);
  align-items: stretch;
  display: inline-flex;
}

.join :where(.join-item), .join .join-item:not(:first-child):not(:last-child), .join :not(:first-child):not(:last-child) .join-item {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.join .join-item:first-child:not(:last-child), .join :first-child:not(:last-child) .join-item {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.join .dropdown .join-item:first-child:not(:last-child), .join :first-child:not(:last-child) .dropdown .join-item {
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
}

.join :where(.join-item:first-child:not(:last-child)), .join :where(:first-child:not(:last-child) .join-item) {
  border-start-start-radius: inherit;
  border-end-start-radius: inherit;
}

.join .join-item:last-child:not(:first-child), .join :last-child:not(:first-child) .join-item {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.join :where(.join-item:last-child:not(:first-child)), .join :where(:last-child:not(:first-child) .join-item) {
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
}

@supports not selector(:has(*)) {
  :where(.join *) {
    border-radius: inherit;
  }
}

@supports selector(:has(*)) {
  :where(.join :has(.join-item)) {
    border-radius: inherit;
  }
}

.link {
  cursor: pointer;
  text-decoration-line: underline;
}

.menu li.disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  color: var(--fallback-bc, oklch(var(--bc) / .3));
}

.modal {
  pointer-events: none;
  opacity: 0;
  overscroll-behavior: contain;
  z-index: 999;
  color: inherit;
  background-color: #0000;
  justify-items: center;
  width: 100%;
  max-width: none;
  height: 100%;
  max-height: none;
  margin: 0;
  padding: 0;
  transition-property: transform, opacity, visibility;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: grid;
  position: fixed;
  inset: 0;
  overflow-y: hidden;
}

:where(.modal) {
  align-items: center;
}

.modal-box {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-bottom-right-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  overscroll-behavior: contain;
  grid-row-start: 1;
  grid-column-start: 1;
  width: 91.6667%;
  max-width: 32rem;
  max-height: calc(100vh - 5em);
  padding: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  overflow-y: auto;
  box-shadow: 0 25px 50px -12px #00000040;
}

.modal-open, .modal:target, .modal-toggle:checked + .modal, .modal[open] {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}

.modal-action {
  justify-content: flex-end;
  margin-top: 1.5rem;
  display: flex;
}

:root:has(:is(.modal-open, .modal:target, .modal-toggle:checked + .modal, .modal[open])) {
  scrollbar-gutter: stable;
  overflow: hidden;
}

.radio {
  --chkbg: var(--bc);
  cursor: pointer;
  appearance: none;
  border-width: 1px;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  --tw-border-opacity: .2;
  border-radius: 9999px;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.range {
  cursor: pointer;
  appearance: none;
  --range-shdw: var(--fallback-bc, oklch(var(--bc) / 1));
  border-radius: var(--rounded-box, 1rem);
  background-color: #0000;
  width: 100%;
  height: 1.5rem;
  overflow: hidden;
}

.range:focus {
  outline: none;
}

.select {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  appearance: none;
  border-radius: var(--rounded-btn, .5rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  background-image: linear-gradient(45deg, #0000 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, #0000 50%);
  background-position: calc(100% - 20px) calc(1px + 50%), calc(100% - 16.1px) calc(1px + 50%);
  background-repeat: no-repeat;
  background-size: 4px 4px, 4px 4px;
  border-width: 1px;
  border-color: #0000;
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 2.5rem;
  font-size: .875rem;
  line-height: 2;
  display: inline-flex;
}

.select[multiple] {
  height: auto;
}

.table {
  border-radius: var(--rounded-box, 1rem);
  text-align: left;
  width: 100%;
  font-size: .875rem;
  line-height: 1.25rem;
  position: relative;
}

.table :where(.table-pin-rows thead tr) {
  z-index: 1;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  position: sticky;
  top: 0;
}

.table :where(.table-pin-rows tfoot tr) {
  z-index: 1;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  position: sticky;
  bottom: 0;
}

.table :where(.table-pin-cols tr th) {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  position: sticky;
  left: 0;
  right: 0;
}

.toggle {
  --tglbg: var(--fallback-b1, oklch(var(--b1) / 1));
  --handleoffset: 1.5rem;
  --handleoffsetcalculator: calc(var(--handleoffset) * -1);
  --togglehandleborder: 0 0;
  cursor: pointer;
  appearance: none;
  border-radius: var(--rounded-badge, 1.9rem);
  color: var(--fallback-bc, oklch(var(--bc) / .5));
  transition: background, box-shadow var(--animation-input, .2s) ease-out;
  box-shadow: var(--handleoffsetcalculator) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset, var(--togglehandleborder);
  background-color: currentColor;
  border-width: 1px;
  border-color: currentColor;
  flex-shrink: 0;
  width: 3rem;
  height: 1.5rem;
}

.btm-nav > :where(.active) {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  border-top-width: 2px;
}

.btm-nav > .disabled, .btm-nav > [disabled] {
  pointer-events: none;
  --tw-border-opacity: 0;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  --tw-bg-opacity: .1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-text-opacity: .2;
}

.btm-nav > * .label {
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (prefers-reduced-motion: no-preference) {
  .btn {
    animation: button-pop var(--animation-btn, .25s) ease-out;
  }
}

.btn:active:hover, .btn:active:focus {
  transform: scale(var(--btn-focus-scale, .97));
  animation: ease-out button-pop;
}

@supports not (color: oklch(0% 0 0)) {
  .btn {
    background-color: var(--btn-color, var(--fallback-b2));
    border-color: var(--btn-color, var(--fallback-b2));
  }
}

@supports (color: color-mix(in oklab, black, black)) {
  .btn-outline.btn-primary.btn-active {
    background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
  }

  .btn-outline.btn-secondary.btn-active {
    background-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
  }

  .btn-outline.btn-accent.btn-active {
    background-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
  }

  .btn-outline.btn-success.btn-active {
    background-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
  }

  .btn-outline.btn-info.btn-active {
    background-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
  }

  .btn-outline.btn-warning.btn-active {
    background-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
  }

  .btn-outline.btn-error.btn-active {
    background-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
  }
}

.btn:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
}

.btn.glass {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-color: currentColor;
}

.btn.glass.btn-active {
  --glass-opacity: 25%;
  --glass-border-opacity: 15%;
}

.btn-outline {
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
  border-color: currentColor;
}

.btn-outline.btn-active {
  --tw-border-opacity: 1;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-b1, oklch(var(--b1) / var(--tw-text-opacity)));
}

.btn-outline.btn-primary {
  --tw-text-opacity: 1;
  color: var(--fallback-p, oklch(var(--p) / var(--tw-text-opacity)));
}

.btn-outline.btn-primary.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
}

.btn-outline.btn-secondary {
  --tw-text-opacity: 1;
  color: var(--fallback-s, oklch(var(--s) / var(--tw-text-opacity)));
}

.btn-outline.btn-secondary.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-sc, oklch(var(--sc) / var(--tw-text-opacity)));
}

.btn-outline.btn-accent {
  --tw-text-opacity: 1;
  color: var(--fallback-a, oklch(var(--a) / var(--tw-text-opacity)));
}

.btn-outline.btn-accent.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-ac, oklch(var(--ac) / var(--tw-text-opacity)));
}

.btn-outline.btn-success {
  --tw-text-opacity: 1;
  color: var(--fallback-su, oklch(var(--su) / var(--tw-text-opacity)));
}

.btn-outline.btn-success.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-suc, oklch(var(--suc) / var(--tw-text-opacity)));
}

.btn-outline.btn-info {
  --tw-text-opacity: 1;
  color: var(--fallback-in, oklch(var(--in) / var(--tw-text-opacity)));
}

.btn-outline.btn-info.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-inc, oklch(var(--inc) / var(--tw-text-opacity)));
}

.btn-outline.btn-warning {
  --tw-text-opacity: 1;
  color: var(--fallback-wa, oklch(var(--wa) / var(--tw-text-opacity)));
}

.btn-outline.btn-warning.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-wac, oklch(var(--wac) / var(--tw-text-opacity)));
}

.btn-outline.btn-error {
  --tw-text-opacity: 1;
  color: var(--fallback-er, oklch(var(--er) / var(--tw-text-opacity)));
}

.btn-outline.btn-error.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-erc, oklch(var(--erc) / var(--tw-text-opacity)));
}

.btn.btn-disabled, .btn[disabled], .btn:disabled {
  --tw-border-opacity: 0;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  --tw-bg-opacity: .2;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-text-opacity: .2;
}

.btninput[type="checkbox"]:checked, .btninput[type="radio"]:checked {
  --tw-border-opacity: 1;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
}

.btninput[type="checkbox"]:checked:focus-visible, .btninput[type="radio"]:checked:focus-visible {
  outline-color: var(--fallback-p, oklch(var(--p) / 1));
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .98));
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.checkbox:focus {
  box-shadow: none;
}

.checkbox:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
}

.checkbox:disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  opacity: .2;
  border-width: 0;
  border-color: #0000;
}

.checkbox:checked, .checkbox[aria-checked="true"] {
  animation: checkmark var(--animation-input, .2s) ease-out;
  background-repeat: no-repeat;
  background-color: var(--chkbg);
  background-image: linear-gradient(-45deg, transparent 65%, var(--chkbg) 65.99%), linear-gradient(45deg, transparent 75%, var(--chkbg) 75.99%), linear-gradient(-45deg, var(--chkbg) 40%, transparent 40.99%), linear-gradient(45deg, var(--chkbg) 30%, var(--chkfg) 30.99%, var(--chkfg) 40%, transparent 40.99%), linear-gradient(-45deg, var(--chkfg) 50%, var(--chkbg) 50.99%);
}

.checkbox:indeterminate {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  animation: checkmark var(--animation-input, .2s) ease-out;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, transparent 80%, var(--chkbg) 80%), linear-gradient(-90deg, transparent 80%, var(--chkbg) 80%), linear-gradient(0deg, var(--chkbg) 43%, var(--chkfg) 43%, var(--chkfg) 57%, var(--chkbg) 57%);
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

details.collapse {
  width: 100%;
}

details.collapse summary {
  outline-offset: 2px;
  outline: 2px solid #0000;
  display: block;
  position: relative;
}

details.collapse summary::-webkit-details-marker {
  display: none;
}

.collapse:focus-visible, .collapse:has(.collapse-title:focus-visible), .collapse:has( > input[type="checkbox"]:focus-visible), .collapse:has( > input[type="radio"]:focus-visible) {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
}

.collapse-arrow > .collapse-title:after {
  --tw-translate-y: -100%;
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  content: "";
  transform-origin: 75% 75%;
  pointer-events: none;
  top: 1.9rem;
  width: .5rem;
  height: .5rem;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: block;
  position: absolute;
  inset-inline-end: 1.4rem;
  box-shadow: 2px 2px;
}

.collapse-plus > .collapse-title:after {
  content: "+";
  pointer-events: none;
  top: .9rem;
  width: .5rem;
  height: .5rem;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: block;
  position: absolute;
  inset-inline-end: 1.4rem;
}

.collapse:not(.collapse-open):not(.collapse-close) > input[type="checkbox"], .collapse:not(.collapse-open):not(.collapse-close) > input[type="radio"]:not(:checked), .collapse:not(.collapse-open):not(.collapse-close) > .collapse-title {
  cursor: pointer;
}

.collapse:focus:not(.collapse-open):not(.collapse-close):not(.collapse[open]) > .collapse-title {
  cursor: unset;
}

.collapse-title {
  position: relative;
}

:where(.collapse > input[type="checkbox"]), :where(.collapse > input[type="radio"]) {
  z-index: 1;
}

.collapse-title, :where(.collapse > input[type="checkbox"]), :where(.collapse > input[type="radio"]) {
  padding: 1rem;
  width: 100%;
  min-height: 3.75rem;
  padding-inline-end: 3rem;
  transition: background-color .2s ease-out;
}

.collapse[open] > :where(.collapse-content), .collapse-open > :where(.collapse-content), .collapse:focus:not(.collapse-close) > :where(.collapse-content), .collapse:not(.collapse-close) > :where(input[type="checkbox"]:checked ~ .collapse-content), .collapse:not(.collapse-close) > :where(input[type="radio"]:checked ~ .collapse-content) {
  padding-bottom: 1rem;
  transition: padding .2s ease-out, background-color .2s ease-out;
}

.collapse[open].collapse-arrow > .collapse-title:after, .collapse-open.collapse-arrow > .collapse-title:after, .collapse-arrow:focus:not(.collapse-close) > .collapse-title:after, .collapse-arrow:not(.collapse-close) > input[type="checkbox"]:checked ~ .collapse-title:after, .collapse-arrow:not(.collapse-close) > input[type="radio"]:checked ~ .collapse-title:after {
  --tw-translate-y: -50%;
  --tw-rotate: 225deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.collapse[open].collapse-plus > .collapse-title:after, .collapse-open.collapse-plus > .collapse-title:after, .collapse-plus:focus:not(.collapse-close) > .collapse-title:after, .collapse-plus:not(.collapse-close) > input[type="checkbox"]:checked ~ .collapse-title:after, .collapse-plus:not(.collapse-close) > input[type="radio"]:checked ~ .collapse-title:after {
  content: "−";
}

.divider:not(:empty) {
  gap: 1rem;
}

.dropdown.dropdown-open .dropdown-content, .dropdown:focus .dropdown-content, .dropdown:focus-within .dropdown-content {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.input input {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity)));
  background-color: #0000;
}

.input input:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.input[list]::-webkit-calendar-picker-indicator {
  line-height: 1em;
}

.input:focus, .input:focus-within {
  box-shadow: none;
  border-color: var(--fallback-bc, oklch(var(--bc) / .2));
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.input:has( > input[disabled]), .input-disabled, .input:disabled, .input[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  color: var(--fallback-bc, oklch(var(--bc) / .4));
}

.input:has( > input[disabled])::placeholder, .input-disabled::placeholder, .input:disabled::placeholder, .input[disabled]::placeholder {
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-placeholder-opacity)));
  --tw-placeholder-opacity: .2;
}

.input:has( > input[disabled]) > input[disabled] {
  cursor: not-allowed;
}

.input::-webkit-date-and-time-value {
  text-align: inherit;
}

.join > :where(:not(:first-child)) {
  margin-top: 0;
  margin-bottom: 0;
  margin-inline-start: -1px;
}

.link:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.loading {
  pointer-events: none;
  aspect-ratio: 1;
  background-color: currentColor;
  width: 1.5rem;
  display: inline-block;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' stroke='%23000' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_V8m1%7Btransform-origin:center;animation:spinner_zKoa 2s linear infinite%7D.spinner_V8m1 circle%7Bstroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite%7D%40keyframes spinner_zKoa%7B100%25%7Btransform:rotate(360deg)%7D%7D%40keyframes spinner_YpZS%7B0%25%7Bstroke-dasharray:0 150;stroke-dashoffset:0%7D47.5%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-16%7D95%25%2C100%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-59%7D%7D%3C%2Fstyle%3E%3Cg class='spinner_V8m1'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3'%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' stroke='%23000' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_V8m1%7Btransform-origin:center;animation:spinner_zKoa 2s linear infinite%7D.spinner_V8m1 circle%7Bstroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite%7D%40keyframes spinner_zKoa%7B100%25%7Btransform:rotate(360deg)%7D%7D%40keyframes spinner_YpZS%7B0%25%7Bstroke-dasharray:0 150;stroke-dashoffset:0%7D47.5%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-16%7D95%25%2C100%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-59%7D%7D%3C%2Fstyle%3E%3Cg class='spinner_V8m1'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3'%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.menu li > :not(ul, .menu-title, details, .btn):active, .menu li > :not(ul, .menu-title, details, .btn).active, .menu li > details > summary:active {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
}

.mockup-phone .display {
  border-radius: 40px;
  margin-top: -25px;
  overflow: hidden;
}

.mockup-browser .mockup-browser-toolbar .input {
  text-overflow: ellipsis;
  white-space: nowrap;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  direction: ltr;
  width: 24rem;
  height: 1.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  display: block;
  position: relative;
  overflow: hidden;
}

.mockup-browser .mockup-browser-toolbar .input:before {
  content: "";
  aspect-ratio: 1;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  border-width: 2px;
  border-color: currentColor;
  border-radius: 9999px;
  height: .75rem;
  position: absolute;
  top: 50%;
  left: .5rem;
}

.mockup-browser .mockup-browser-toolbar .input:after {
  content: "";
  --tw-translate-y: 25%;
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  border-width: 1px;
  border-color: currentColor;
  border-radius: 9999px;
  height: .5rem;
  position: absolute;
  top: 50%;
  left: 1.25rem;
}

.modal:not(dialog:not(.modal-open)), .modal::backdrop {
  background-color: #0006;
  animation: .2s ease-out modal-pop;
}

.modal-backdrop {
  z-index: -1;
  color: #0000;
  grid-row-start: 1;
  grid-column-start: 1;
  place-self: stretch stretch;
  display: grid;
}

.modal-open .modal-box, .modal-toggle:checked + .modal .modal-box, .modal:target .modal-box, .modal[open] .modal-box {
  --tw-translate-y: 0px;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.modal-action > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

@keyframes modal-pop {
  0% {
    opacity: 0;
  }
}

@keyframes progress-loading {
  50% {
    background-position-x: -115%;
  }
}

.radio:focus {
  box-shadow: none;
}

.radio:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
}

.radio:checked, .radio[aria-checked="true"] {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  animation: radiomark var(--animation-input, .2s) ease-out;
  box-shadow: 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  background-image: none;
}

.radio:disabled {
  cursor: not-allowed;
  opacity: .2;
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 12px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }

  50% {
    box-shadow: 0 0 0 3px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 3px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }

  100% {
    box-shadow: 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }
}

.range:focus-visible::-webkit-slider-thumb {
  --focus-shadow: 0 0 0 6px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 2rem var(--range-shdw) inset;
}

.range:focus-visible::-moz-range-thumb {
  --focus-shadow: 0 0 0 6px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 2rem var(--range-shdw) inset;
}

.range::-webkit-slider-runnable-track {
  border-radius: var(--rounded-box, 1rem);
  background-color: var(--fallback-bc, oklch(var(--bc) / .1));
  width: 100%;
  height: .5rem;
}

.range::-moz-range-track {
  border-radius: var(--rounded-box, 1rem);
  background-color: var(--fallback-bc, oklch(var(--bc) / .1));
  width: 100%;
  height: .5rem;
}

.range::-webkit-slider-thumb {
  border-radius: var(--rounded-box, 1rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  appearance: none;
  color: var(--range-shdw);
  --filler-size: 100rem;
  --filler-offset: .6rem;
  box-shadow: 0 0 0 3px var(--range-shdw) inset, var(--focus-shadow, 0 0), calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
  border-style: none;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.range::-moz-range-thumb {
  border-radius: var(--rounded-box, 1rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  color: var(--range-shdw);
  --filler-size: 100rem;
  --filler-offset: .5rem;
  box-shadow: 0 0 0 3px var(--range-shdw) inset, var(--focus-shadow, 0 0), calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
  border-style: none;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 50%;
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

.select:focus {
  box-shadow: none;
  border-color: var(--fallback-bc, oklch(var(--bc) / .2));
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.select-disabled, .select:disabled, .select[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  color: var(--fallback-bc, oklch(var(--bc) / .4));
}

.select-disabled::placeholder, .select:disabled::placeholder, .select[disabled]::placeholder {
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-placeholder-opacity)));
  --tw-placeholder-opacity: .2;
}

.select-multiple, .select[multiple], .select[size].select:not([size="1"]) {
  background-image: none;
  padding-right: 1rem;
}

[dir="rtl"] .select {
  background-position: 12px calc(1px + 50%), 16px calc(1px + 50%);
}

@keyframes skeleton {
  from {
    background-position: 150%;
  }

  to {
    background-position: -50%;
  }
}

:is([dir="rtl"] .table) {
  text-align: right;
}

.table :where(th, td) {
  vertical-align: middle;
  padding: .75rem 1rem;
}

.table tr.active, .table tr.active:nth-child(2n), .table-zebra tbody tr:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
}

.table-zebra tr.active, .table-zebra tr.active:nth-child(2n), .table-zebra-zebra tbody tr:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity)));
}

.table :where(thead tr, tbody tr:not(:last-child), tbody tr:first-child:last-child) {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-bottom-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
}

.table :where(thead, tfoot) {
  white-space: nowrap;
  color: var(--fallback-bc, oklch(var(--bc) / .6));
  font-size: .75rem;
  font-weight: 700;
  line-height: 1rem;
}

.table :where(tfoot) {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-top-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
}

@keyframes toast-pop {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

[dir="rtl"] .toggle {
  --handleoffsetcalculator: calc(var(--handleoffset) * 1);
}

.toggle:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.toggle:hover {
  background-color: currentColor;
}

.toggle:checked, .toggle[aria-checked="true"] {
  --handleoffsetcalculator: var(--handleoffset);
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  background-image: none;
}

[dir="rtl"] .toggle:checked, [dir="rtl"] .toggle[aria-checked="true"] {
  --handleoffsetcalculator: calc(var(--handleoffset) * -1);
}

.toggle:indeterminate {
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  box-shadow: calc(var(--handleoffset) / 2) 0 0 2px var(--tglbg) inset, calc(var(--handleoffset) / -2) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset;
}

[dir="rtl"] .toggle:indeterminate {
  box-shadow: calc(var(--handleoffset) / 2) 0 0 2px var(--tglbg) inset, calc(var(--handleoffset) / -2) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset;
}

.toggle:disabled {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  opacity: .3;
  --togglehandleborder: 0 0 0 3px var(--fallback-bc, oklch(var(--bc) / 1)) inset, var(--handleoffsetcalculator) 0 0 3px var(--fallback-bc, oklch(var(--bc) / 1)) inset;
  background-color: #0000;
}

.artboard.phone {
  width: 320px;
}

.artboard.phone-1.horizontal, .artboard.phone-1.artboard-horizontal {
  width: 568px;
  height: 320px;
}

.artboard.phone-2.horizontal, .artboard.phone-2.artboard-horizontal {
  width: 667px;
  height: 375px;
}

.artboard.phone-3.horizontal, .artboard.phone-3.artboard-horizontal {
  width: 736px;
  height: 414px;
}

.artboard.phone-4.horizontal, .artboard.phone-4.artboard-horizontal {
  width: 812px;
  height: 375px;
}

.artboard.phone-5.horizontal, .artboard.phone-5.artboard-horizontal {
  width: 896px;
  height: 414px;
}

.artboard.phone-6.horizontal, .artboard.phone-6.artboard-horizontal {
  width: 1024px;
  height: 320px;
}

.btm-nav-xs > :where(.active) {
  border-top-width: 1px;
}

.btm-nav-sm > :where(.active), .btm-nav-md > :where(.active) {
  border-top-width: 2px;
}

.btm-nav-lg > :where(.active) {
  border-top-width: 4px;
}

.join.join-vertical {
  flex-direction: column;
}

.join.join-vertical .join-item:first-child:not(:last-child), .join.join-vertical :first-child:not(:last-child) .join-item {
  border-start-start-radius: inherit;
  border-start-end-radius: inherit;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.join.join-vertical .join-item:last-child:not(:first-child), .join.join-vertical :last-child:not(:first-child) .join-item {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-end-radius: inherit;
  border-end-start-radius: inherit;
}

.join.join-horizontal {
  flex-direction: row;
}

.join.join-horizontal .join-item:first-child:not(:last-child), .join.join-horizontal :first-child:not(:last-child) .join-item {
  border-start-start-radius: inherit;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: inherit;
}

.join.join-horizontal .join-item:last-child:not(:first-child), .join.join-horizontal :last-child:not(:first-child) .join-item {
  border-start-start-radius: 0;
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
  border-end-start-radius: 0;
}

[type="radio"].radio-xs {
  width: 1rem;
  height: 1rem;
}

.join.join-vertical > :where(:not(:first-child)) {
  margin-top: -1px;
  margin-left: 0;
  margin-right: 0;
}

.join.join-horizontal > :where(:not(:first-child)) {
  margin-top: 0;
  margin-bottom: 0;
  margin-inline-start: -1px;
}

.modal-top :where(.modal-box) {
  --tw-translate-y: -2.5rem;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-bottom-right-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  max-width: none;
}

.modal-middle :where(.modal-box) {
  --tw-translate-y: 0px;
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
  width: 91.6667%;
  max-width: 32rem;
}

.modal-bottom :where(.modal-box) {
  --tw-translate-y: 2.5rem;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  max-width: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-top-10 {
  top: -2.5rem;
}

.-top-5 {
  top: -1.25rem;
}

.bottom-24 {
  bottom: 6rem;
}

.bottom-28 {
  bottom: 7rem;
}

.left-0 {
  left: 0;
}

.left-10 {
  left: 2.5rem;
}

.right-0 {
  right: 0;
}

.right-10 {
  right: 2.5rem;
}

.right-14 {
  right: 3.5rem;
}

.top-0 {
  top: 0;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.table-row {
  display: table-row;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-12 {
  height: 3rem;
}

.h-20 {
  height: 5rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-screen {
  height: 100vh;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-36 {
  width: 9rem;
}

.w-4\/5 {
  width: 80%;
}

.w-40 {
  width: 10rem;
}

.w-6 {
  width: 1.5rem;
}

.w-60 {
  width: 15rem;
}

.w-7 {
  width: 1.75rem;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.min-w-full {
  min-width: 100%;
}

.border-spacing-y-4 {
  --tw-border-spacing-y: 1rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-4 {
  gap: 1rem;
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-\[\#8E7F0080\] {
  border-color: #8e7f0080;
}

.border-\[\#D9D9D9\] {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.bg-\[\#7DA1D6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(125 161 214 / var(--tw-bg-opacity));
}

.bg-\[\#D9D9D9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
}

.bg-\[\#FBFAFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(251 250 255 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.p-5 {
  padding: 1.25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.text-\[\#3A3A3A\] {
  --tw-text-opacity: 1;
  color: rgb(58 58 58 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@font-face {
  font-family: TebalDemo;
  src: url("TheTebalDemo-Regular.8c7868fa.woff") format("woff"), url("TheTebalDemo-Regular.4a606f18.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Author;
  src: url("Author-Variable.32e23800.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Author;
  src: url("Author-VariableItalic.b23f7c78.ttf") format("truetype");
  font-style: italic;
  font-display: swap;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  line-height: 1.15;
}

button, input {
  border-radius: 8px;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  background: #060606 url("starsbg.16ebe800.svg") center / contain no-repeat;
  font-family: Author;
  transition: background-color .5s;
}

main {
  padding: 40px 80px 20px;
}

#loader {
  z-index: 2147483647;
  border: 16px solid #f3f3f3;
  border-top-color: #000;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  animation: 2s linear infinite spin;
  position: absolute;
  top: 40%;
  left: 50%;
}

.spinner {
  width: 24px;
  height: 24px;
  display: inline-block;
}

.opacity-10 {
  z-index: 9998;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.onboarding {
  width: 100%;
  height: 100vh;
  position: fixed;
}

nav {
  color: #fff;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  font-family: Author;
  font-weight: 600;
  display: flex;
}

.home-nav {
  justify-content: flex-end;
  gap: 210px;
  position: relative;
}

#logo {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

nav .nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.nav-list li {
  margin: 0 10px;
}

.nav-list li .nav__link {
  color: #fff;
  cursor: pointer;
  z-index: 10;
  border-radius: 20px;
  padding: 10px 20px;
  text-decoration: none;
}

.nav-list .nav__link:hover, .nav-list .nav__link.active {
  color: #000;
  background-color: #fff;
}

.hamburger {
  display: none;
}

.nav__link:after {
  content: "";
  transform-origin: 100%;
  background-color: currentColor;
  width: 100%;
  height: 2px;
  transition: transform .25s;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scaleX(0);
}

section {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  display: flex;
  position: relative;
}

.header-text {
  color: #fff;
  z-index: 1;
  letter-spacing: -1px;
  width: 100%;
  font-family: TebalDemo;
  font-size: 9.375rem;
  font-weight: 400;
  transition: transform 1s;
  position: absolute;
  top: 130px;
  transform: translateY(0);
}

.header-subtext {
  color: #3a3a3a;
  z-index: 1;
  font-size: 48px;
  font-weight: 600;
  position: absolute;
  top: 29vh;
}

.wheel-img-cont {
  z-index: -1;
  width: 100%;
  position: relative;
}

.wheel-img {
  z-index: -1;
  transition: transform 1s;
  position: relative;
}

.go-to-wheel {
  color: #fff;
  cursor: pointer;
  background-color: #282b2c;
  border: 3px solid #ffef00;
  border-radius: 50px;
  margin: 30px 0 25px;
  padding: 15px 35px;
  font-family: TebalDemo;
  font-size: 18px;
  box-shadow: 1px 2px 12px 5px #ffffffe6;
}

.phone-no {
  text-align: center;
  color: #fff;
  background-color: #0006;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 15px 80px;
  font-family: Author;
  font-size: 24px;
  font-weight: 500;
}

#subscription-collapse:not(.collapse-close) > input[type="checkbox"]:checked ~ #subinfo {
  visibility: visible;
  min-height: 70px;
  margin: 0 16px 16px;
}

.subinfo {
  border: 1px solid #0000001a;
  padding: 0;
}

.login-img {
  width: 65px;
  height: 65px;
}

.terms-condition {
  background-color: #0000;
  background-image: url("starsbg.16ebe800.svg"), linear-gradient(#0d0d19 0%, #1e2046 31%, #3a3086 68.5%, #8e74e2 100%);
  background-position-x: center;
  background-position-y: 0%, 0%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.white-card {
  background-color: #f2f2f2;
  position: relative;
  box-shadow: 0 2px 20px #00000040;
}

.prizes-cont {
  background-color: #0000;
  background-image: url("starsbg.16ebe800.svg"), linear-gradient(#0d0d19 0%, #1e2046 31%, #3a3086 68.5%, #8e74e2 100%);
  background-position-x: center;
  background-position-y: 0%, 0%;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  font-family: Author;
}

.prize-box, .play-prize-box {
  text-align: center;
  cursor: pointer;
  background-color: #fbfaff;
  border: 1px solid #8e7f0080;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  display: flex;
}

.prize-box:hover, .prize-box:focus, .prize-box.active {
  background-color: #fcee21;
}

.highlight {
  background-color: #fcee21;
  border: 2px solid gold;
}

.prize-box > span, .play-prize-box > span {
  text-transform: capitalize;
  font-weight: 600;
}

.description-box {
  border: 2px solid #0000;
  margin-bottom: 15px;
  transition: border-color .3s;
  display: block;
}

.date {
  color: #2e2d32b2;
}

.table-row {
  background-color: #fbfaff;
  border: 1px solid #8e7f0080;
  border-radius: 16px;
  padding: 10px;
}

#spin_button {
  margin: 0;
}

#canvas {
  background-image: url("wheel-bg.e576ffd8.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}

#pointer {
  width: min-content;
  margin: auto;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translate(-50%);
}

#hold-text {
  font-family: TebalDemo;
}

@media only screen and (width <= 1085px) {
  .prize-box, .play-prize-box {
    padding: 10px;
  }

  nav {
    justify-content: space-between;
    gap: 0;
  }

  .nav-list li .nav__link {
    padding: 10px 16px;
  }

  .home-nav {
    justify-content: space-between;
    gap: 0;
  }
}

@media (width >= 768px) {
  .header-text.transform-up {
    transform: translateY(-80px);
  }

  .wheel-img.transform-down {
    transform: translateY(500px);
  }

  .wheel-img.transform-up {
    transform: rotate(360deg)translateY(0);
  }
}

@media only screen and (width <= 767px) {
  body {
    background: #060606 url("starsmobile.b46b677e.svg") center / contain no-repeat;
  }

  .terms-condition, .prizes-cont {
    background: url("starsmobile.b46b677e.svg"), linear-gradient(#0d0d19 0%, #1e2046 31%, #3a3086 68.5%, #8e74e2 100%);
  }

  main {
    padding: 40px 30px;
    position: relative;
  }

  nav .nav-list {
    padding: 70px 0 0;
  }

  .nav-list {
    z-index: 999999;
    -webkit-clip-path: circle(50px at 90% -10%);
    clip-path: circle(50px at 90% -20%);
    pointer-events: none;
    background-color: #d9d9d9e5;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100vw;
    height: 70vh;
    transition: all .8s ease-out;
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
  }

  .nav-list > * + * {
    margin-top: 40px;
  }

  .nav-list.open {
    clip-path: circle(1000px at 90% -10%);
    pointer-events: all;
  }

  nav .nav-list, .profile-icon {
    display: none;
  }

  nav .nav-list li {
    margin: 10px 0;
  }

  .nav-list li .nav__link {
    color: #000;
  }

  .nav-list .nav__link.active, .nav-list .nav__link:hover {
    color: #fff;
    background-color: #000;
  }

  .hamburger {
    cursor: pointer;
    z-index: 999999;
    height: 16px;
    display: grid;
    top: 69%;
    right: 3%;
  }

  .hamburger .line1, .hamburger .line2, .hamburger .line3 {
    background: #fff;
    border-radius: 1000px;
    width: 25px;
    height: 2px;
    transition: all .8s;
  }

  .togle .line1 {
    background-color: #000;
    border-radius: 100px;
    transform: rotate(-45deg)translate(-6px, 6px);
  }

  .togle .line2 {
    display: none;
  }

  .togle .line3 {
    background-color: #000;
    border-radius: 100px;
    transform: rotate(45deg)translate(1px, -1px);
  }

  .header-text {
    font-size: 4.8rem;
  }

  .header-text.transform-up {
    transform: translateY(-50px);
  }

  .wheel-img.transform-down {
    transform: translateY(300px);
  }

  .wheel-img.transform-up {
    transform: rotate(60deg)translateY(0);
  }

  .header-subtext {
    font-size: 36px;
    top: 26vh;
  }

  .wheel-img-cont {
    width: 80%;
  }

  .wheel-img {
    width: 100%;
  }

  .wheel-img.transform-img {
    transform: translateY(200px);
  }

  .phone-no {
    width: 100%;
    padding: 16px 0;
    font-size: 18px;
  }

  .description-box.desktop {
    display: none;
  }

  .description-box.mobile {
    border: 2px solid #fcee21;
    display: block;
  }

  .stars {
    width: 130px;
    height: 69px;
  }

  #canvas, .wheel-container {
    width: 100%;
  }
}

@media only screen and (width <= 500px) {
  main {
    padding: 20px;
    position: relative;
  }

  .header-text {
    font-size: 3rem;
    top: 20%;
  }

  .wheel-img-cont {
    width: 90%;
  }

  .wheel-img {
    width: 100%;
  }

  .go-to-wheel {
    padding: 15px;
  }

  .header-subtext {
    top: 20vh;
  }
}

.checked\:bg-\[\#3F87F0\]:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(63 135 240 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#a0bde9\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(160 189 233 / var(--tw-bg-opacity));
}

.hover\:bg-slate-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

@media not all and (width >= 1024px) {
  .max-lg\:absolute {
    position: absolute;
  }

  .max-lg\:mt-12 {
    margin-top: 3rem;
  }

  .max-lg\:mt-8 {
    margin-top: 2rem;
  }
}

@media not all and (width >= 768px) {
  .max-md\:w-4\/5 {
    width: 80%;
  }
}

@media (width <= 450px) {
  .max-\[450px\]\:h-\[28rem\] {
    height: 28rem;
  }
}

@media (width >= 500px) {
  .min-\[500px\]\:mt-6 {
    margin-top: 1.5rem;
  }
}

@media (width >= 640px) {
  .sm\:bottom-24 {
    bottom: 6rem;
  }

  .sm\:h-32 {
    height: 8rem;
  }

  .sm\:w-\[416px\] {
    width: 416px;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 768px) {
  .md\:-top-10 {
    top: -2.5rem;
  }

  .md\:-top-14 {
    top: -3.5rem;
  }

  .md\:bottom-3 {
    bottom: .75rem;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-32 {
    width: 8rem;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-\[401px\] {
    width: 401px;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1024px) {
  .lg\:bottom-3 {
    bottom: .75rem;
  }

  .lg\:right-40 {
    right: 10rem;
  }

  .lg\:h-screen {
    height: 100vh;
  }

  .lg\:w-2\/5 {
    width: 40%;
  }

  .lg\:basis-3\/5 {
    flex-basis: 60%;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}
/*# sourceMappingURL=index.267430b4.css.map */
