@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: TebalDemo;
  src: url("../fonts/TheTebalDemo-Regular.woff") format("woff"),
    url("../fonts/TheTebalDemo-Regular.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Author;
  src: url('../fonts/Author-Variable.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: Author;
  src: url('../fonts/Author-VariableItalic.ttf') format('truetype');
  font-style: italic;
  font-display: swap;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  line-height: 1.15;
  scroll-behavior: smooth;
  /* height: 100%; */
}
button, input {
  border-radius: 8px;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  font-family: Author;
  background: #060606 url(../images/starsbg.svg) no-repeat center/contain;
  transition: background-color 0.5s ease;
}

main{
  padding: 40px 80px 20px;
}
#loader {
  position: absolute;
  left: 50%;
  top: 40%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #000;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  z-index: 9999999999;
}
.spinner {
  width: 24px;
  height: 24px;
  display: inline-block;
}

.opacity-10 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998; /* Ensure it's just below the loader */
}


@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.onboarding {
  height: 100vh;
  position: fixed;
  width: 100%;
}

/* NAVIGATION STYLES */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-weight: 600;
  z-index: 10;
  margin-bottom: 30px;
  font-family: Author;
}
.home-nav {
  position: relative;
  justify-content: flex-end;
  gap: 210;
}

#logo{
  position: absolute;
  left: 50%;
  top: -10;
  transform: translateX(-50%);
}


nav .nav-list {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;

}


.nav-list li {
  margin: 0 10px;
}

.nav-list li .nav__link {
  text-decoration: none;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  padding: 10px 20px;
  z-index: 10;
}

.nav-list .nav__link:hover{
  background-color: #fff;
  color: #000;
}
.nav-list .nav__link.active{
  background-color: #fff;
  color: #000;
}

.hamburger{
  display: none;
}


.nav__link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: currentColor;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 250ms ease;
}
/* END OF NAVIGATION STYLES */



/* ONBOARDING SECTION and ONLOAD ANIMATION*/
section {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 60px;

}

.header-text {
  font-family: TebalDemo;
  color: white;
  font-size: 9.375rem;
  font-weight: 400;
  z-index: 1;
  position: absolute;
  top: 130px;
  letter-spacing: -1px;
  width: 100%;
  transition: transform 1s ease;
  transform: translateY(0);
}

.header-subtext{
  font-size: 48px;
  font-weight: 600;
  color: #3A3A3A;
  z-index: 1;
  position: absolute;
  top: 29vh;
}

.wheel-img-cont {
  position: relative;
  z-index: 0;
  width: 100%;
  z-index: -1;
}

.wheel-img {
  z-index: -1;
  position: relative;
  transition: transform 1s ease;
  /* transform: rotate(-60deg) translateY(0); */
}
.go-to-wheel{
  margin: 30px 0 25px;
  color: white;
  cursor: pointer;
  border-radius: 50px;
  padding: 15px 35px;
  background-color: #282B2C;
  border: 3px solid #FFEF00;
  font-size: 18px;
  font-family: TebalDemo;
  box-shadow: 1px 2px 12px 5px rgba(255, 255, 255, 0.9);
}

.phone-no{
  background-color: #00000066;
  padding: 15px 80px;
  text-align: center;
  border-radius: 5px;
  font-size: 24px;
  font-weight: 500;
  font-family: Author;
  color: white;
  border: 1px solid white;
}

 /* Modals */
 #subscription-collapse:not(.collapse-close) > input[type="checkbox"]:checked ~ #subinfo {
  visibility: visible;
  min-height: 70px;
  margin: 0 16px 16px;
}

.subinfo{
  border: 1px solid #0000001A;
  padding: 0;
}

.login-img{
  width: 65px;
  height: 65px;
}



/* Terms And Conditions */
.terms-condition{
  background:    url('../images/starsbg.svg'), linear-gradient(180deg, #0D0D19 0%, #1E2046 31%, #3A3086 68.5%, #8E74E2 100%);
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
}
.white-card {
  position: relative;
  background-color: #F2F2F2;
  box-shadow: 0 2px 20px 0 #00000040;
}


/* Prizes  */
.prizes-cont{
  background:    url('../images/starsbg.svg'), linear-gradient(180deg, #0D0D19 0%, #1E2046 31%, #3A3086 68.5%, #8E74E2 100%);
  /* background-repeat: no-repeat; */
  background-size: cover;
  background-position-x: center;
  font-family: Author;
}

.prize-box, .play-prize-box {
  background-color: #FBFAFF;
  border: 1px solid #8E7F0080;
  padding: 10px 30px;
  text-align: center;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.prize-box:hover, .prize-box:focus, .prize-box.active {
  background-color: #FCEE21;
}
.highlight {
  border: 2px solid #FFD700;
  background-color: #FCEE21;
}

.prize-box >  span, .play-prize-box >  span {
  font-weight: 600;
  text-transform: capitalize;
}

.description-box {
  display: block;
  margin-bottom: 15px;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}
.date{
  color: #2E2D32B2;
}
.table-row{
  background-color: #FBFAFF;
  border: 1px solid #8E7F0080;
  padding: 10px;
  border-radius: 16px;
}

/* Play and spin */
#spin_button {
  margin: 0;
}


#canvas{
  background-image: url(../images/wheel-bg.svg);
  background-repeat: no-repeat;
  background-size: contain;
    width: 100%;

  /* height: auto;
  width: 100%;
  z-index: 99999; */

}
#pointer{
  width: min-content;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 8px;
  transform: translate(-50%, 0);
}

#hold-text {
  font-family: TebalDemo;
}


/* MEDIA QUERY */
@media only screen and (max-width: 1085px) {

  .prize-box, .play-prize-box {
    padding: 10px 10px;
  }

  nav {
    justify-content: space-between;
    gap: 0;
  }
  .nav-list li .nav__link {
    padding: 10px 16px;
  }
  .home-nav {
    justify-content: space-between;
    gap: 0;
  }
}



/* Default transform for larger screens */
@media (min-width: 768px) {
  .header-text.transform-up {
    transform: translateY(-80px);
  }

  .wheel-img.transform-down {
    transform: translateY(500px);
  }

  .wheel-img.transform-up {
    transform: rotate(360deg) translateY(0);
  }
}

/* Tab screens */
@media only screen and (max-width: 767px) {

  body {
    background: #060606 url(../images/starsmobile.svg) no-repeat center/contain;

  }
  .terms-condition, .prizes-cont{
    background:  url(../images/starsmobile.svg) repeat, linear-gradient(180deg, #0D0D19 0%, #1E2046 31%, #3A3086 68.5%, #8E74E2 100%);
  }

  main{
    position: relative;
    padding: 40px 30px;
  }
  nav .nav-list {
    padding: 70px 0 0;

  }

  /* start of hamburger menu */
  .nav-list {
    position: absolute;
    left: 0;
    right: 0;
    top: -20;
    background-color: #D9D9D9E5;
    height: 70vh;
    width: 100vw;
    z-index: 999999;
    flex-direction: column;
    -webkit-clip-path: circle(50px at 90% -10%);
    clip-path: circle(50px at 90% -20%);
    transition: all 0.8s ease-out;
    pointer-events: none;
    /* justify-content: center; */
    align-items: center;
    gap: 20px;
  }

  .nav-list > * + * {
    margin-top: 40px;
  }

  .nav-list.open {
    -webkit-clip-path: circle(1000px at 90% -10%);
    clip-path: circle(1000px at 90% -10%);
    pointer-events: all;
  }

  nav .nav-list, .profile-icon {
    display: none;
  }


  nav .nav-list li {
    margin: 10px 0;
  }
  .nav-list li .nav__link {
    color: black;
  }
  .nav-list .nav__link.active{
    background-color: black;
    color: white;
  }
  .nav-list .nav__link:hover{
    background-color: black;
    color: white;
  }

  .hamburger {
    display: grid;
    cursor: pointer;
    right: 3%;
    top: 69%;
    height: 16px;
    z-index: 999999;
  }

  .hamburger .line1,.hamburger .line2,
  .hamburger .line3 {
    height: 2px;
    background: white;
    width: 25px;
    border-radius: 1000px;
    transition: 0.8s ease;
  }

  .togle .line1 {
    transform: rotate(-45deg) translate(-6px, 6px);
    background-color: black;
    border-radius: 100px;
  }
  .togle .line2 {
    display: none;
  }

  .togle .line3 {
    transform: rotate(45deg) translate(1px, -1px);
    background-color:  black;
    border-radius: 100px;
  }
/* end of hamburger menu */

  .header-text {
    font-size: 4.8rem;
    /* top: 20vh; */
  }
  .header-text.transform-up {
    transform: translateY(-50px);
  }

  .wheel-img.transform-down {
    transform: translateY(300px); /* Adjusted value for mobile */
  }

  .wheel-img.transform-up {
    transform: rotate(60deg) translateY(0);
  }

  .header-subtext{
    font-size: 36px;
    top: 26vh;
  }
  .wheel-img-cont {
    width: 80%;
  }
  .wheel-img {
    width: 100%;
  }
  .wheel-img.transform-img {
    transform: translateY(200px);
  }

  .phone-no{
    width: 100%;
    font-size: 18px;
    padding: 16px 0px;
  }
  .description-box.desktop {
    display: none;
  }
  .description-box.mobile {
    display: block;
    border: 2px solid #FCEE21; /* Border for mobile */
  }
  .stars{
    width: 130px;
    height: 69px;
  }
  #canvas, .wheel-container{
    width: 100%;
  }

}


/* Mobile screens */
@media only screen and (max-width: 500px) {
  main{
    position: relative;
    padding: 20px;
  }

  .header-text {
    font-size: 3rem;
    top: 20%;
  }

  .wheel-img-cont {
    width: 90%;
  }
  .wheel-img {
    width: 100%;
  }
  .go-to-wheel{
    padding: 15px;
  }
  .header-subtext{
    top: 20vh;
  }
}

